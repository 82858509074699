import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import {
    Notify
} from "vant";
import Env from "@/logics/Envs.js";

export default async function (userId, start) {
    try {
        const toSend = new FormData();
        toSend.append("userId", userId);
        toSend.append("start", start);

        const res = await axios.post(Env.apiPath + "startUser", toSend);
        if (res.data.error) {
            Notify({
                type: "danger",
                message: res.data.error,
                duration: 5000
            });
            return false;
        }
        return true;
    } catch (err) {
        CheckHttp(err);
        return false;
    }
}