<template>
    <div class="pt-32 flex flex-col items-center px-4 gap-8">
        <van-radio-group v-model="listMode">
            <van-cell-group inset class="w-40">
                <van-cell title="Card View" clickable @click="listMode = 'card'">
                    <template #right-icon>
                        <van-radio name="card" />
                    </template>
                </van-cell>
                <van-cell title="List View" clickable @click="listMode = 'list'">
                    <template #right-icon>
                        <van-radio name="list" />
                    </template>
                </van-cell>
            </van-cell-group>
        </van-radio-group>
        <van-cell-group inset class="shadow-md md:w-2/3 w-full" v-if="listMode === 'list'">
            <vue3-datatable :rows="rows" :columns="cols" :sortable="true" :pagination="true" :pageSize="10" ref="table">
                <template #tags="data">
                    <div class="flex flex-row flex-wrap gap-1">
                        <div v-for="(tag, index) in data.value.tags.personal" :key="index"
                            class="bg-blue-400 text-white px-1 rounded-sm text-xs">{{ tag }}</div>
                        <div v-for="(tag, index) in data.value.tags.system" :key="index"
                            class="bg-purple-500 text-white px-1 rounded-sm text-xs">{{ tag }}</div>
                    </div>
                </template>
            </vue3-datatable>
        </van-cell-group>
        <div class="md:w-1/2 w-full flex flex-col gap-8" v-else-if="listMode === 'card'">
            <div class="flex flex-row justify-center">
                <van-stepper v-model="pageOfList" input-width="40px" button-size="32px" :long-press="false"
                    @plus="nextPage()" @minus="lastPage()" @change="setPage" :min="1" :max="maxPage" />
            </div>
            <van-cell-group class="shadow-md" inset v-for="student in students" :key="student.userId">
                <profile-list-cell :user="student" :active-text="'启用'" :show-active="isManager" :show-delete="isManager"
                    @click-icon="showProfile(student)" @remove-personal-tag="removePersonalTag"
                    @remove-system-tag="removeSystemTag" @show-tag-input="showTagInput" @report-active="reportActive"
                    @delete-user="deleteStudent" :show-log="canManageStudent" />
                <van-cell>
                    <template #value>
                        <div class="flex flex-row-reverse flex-wrap" v-if="student.active">
                            <van-button v-if="canManageStudent" class="ml-4 mb-2" plain type="primary" size="small"
                                @click="goStudentClass(student)">学生管理</van-button>
                            <van-button v-if="canManageStudent" class="ml-4 mb-2" plain type="primary" size="small"
                                @click="goStudentPayments(student)">缴费记录</van-button>
                        </div>
                    </template>
                </van-cell>
            </van-cell-group>
            <div class="flex flex-row justify-center">
                <van-stepper v-model="pageOfList" input-width="40px" button-size="32px" :long-press="false"
                    @plus="nextPage()" @minus="lastPage()" @change="setPage" :min="1" :max="maxPage" />
            </div>
        </div>
    </div>
    <profile-viewer ref="viewProfileRef" :lang-code="'zh'" />
    <van-dialog v-model:show="showAddTag" title="添加系统标签" show-cancel-button @confirm="putTag()"
        :confirm-button-color="'#ee0a24'">
        <div class="text-center">
            <input class="border border-purple-500 w-10/12 p-1 my-4" type="text" v-model="newTag"
                @keyup.enter="putTag()" />
        </div>
    </van-dialog>
    <confirm-password ref="confirmPassword" @password-ok="confirmDelete" />
</template>

<script>
import lang from "@/langs/PageStudentsLang.js";
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRouter,
    useRoute
} from "vue-router";
import {
    Cell,
    CellGroup,
    Button,
    Toast,
    Dialog,
    RadioGroup,
    Radio,
    Stepper
} from "vant";
import UserTypes from "@/static_values/StaticUserTypes.js";
import Env from "@/logics/Envs.js";
import ListStudent from "@/asyncs/ListStudent.js";
import ProfileViewer from "@/components/dialog/ProfileViewer.vue";
import ProfileListCell from "@/components/list/ProfileListCell.vue";
import ConfirmPassword from "@/components/dialog/ConfirmPassword.vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import StartUser from "@/asyncs/StartUser.js";
import GetSession from "@/asyncs/GetSession.js";
import AddTag from "@/asyncs/AddTag.js";
import RemoveTag from "@/asyncs/RemoveTag.js";
import DeleteStudent from "@/asyncs/DeleteStudent.js";
import PaginationController from "@/logics/PaginationController.js";

export default {
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [Dialog.Component.name]: Dialog.Component,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Stepper.name]: Stepper,
        ProfileViewer,
        ProfileListCell,
        ConfirmPassword,
        Vue3Datatable
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        const router = useRouter();
        const route = useRoute();
        const students = ref([]);
        let pageController = null;
        const pageOfList = ref(1);
        const maxPage = ref(1);
        const rows = ref([]);
        const cols = ref([
            { field: "firstName", title: "First Name" },
            { field: "lastName", title: "Last Name" },
            { field: "showName", title: "Show Name" },
            { field: "age", title: "Age" },
            { field: "tags", title: "Tag" }
        ]);
        const table = ref(null);
        const listMode = ref("card");
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                store.commit("setPageTitle", lang[store.state.lang].title);
                const studentList = await ListStudent();
                if (!studentList) {
                    return;
                }
                studentList.sort(function (a, b) {
                    if (!a.lastOn) {
                        return 1;
                    }
                    if (!b.lastOn) {
                        return -1;
                    }
                    return b.lastOn - a.lastOn;
                });
                pageController = new PaginationController(5, studentList);
                maxPage.value = pageController.endPage;
                students.value = pageController.page();

                const nowYear = new Date().getFullYear();
                studentList.forEach(function (s) {
                    const year = parseInt(s.profile.dob.split("-")[0]);
                    rows.value.push({
                        firstName: s.profile.firstName,
                        lastName: s.profile.lastName,
                        showName: s.profile.showName,
                        age: nowYear - year,
                        tags: s.meta.tags
                    });
                });
            });
        });
        const nextPage = function () {
            if (!pageController) {
                return;
            }
            students.value = pageController.nextPage();
        };
        const lastPage = function () {
            if (!pageController) {
                return;
            }
            students.value = pageController.lastPage();
        };
        const setPage = function (p) {
            pageOfList.value = pageController.setPage(p);
            students.value = pageController.page();
        };
        const viewProfileRef = ref(null);
        const showProfile = function (student) {
            viewProfileRef.value.showUser(student);
        };
        const reportActive = async function (user, active) {
            const ok = await StartUser(user.userId, active);
            if (ok) {
                user.active = active;
            }
        };

        const showAddTag = ref(false);
        var userTagsForAdd = null;
        var userAddTag = null;
        const newTag = ref("");

        const showTagInput = function (userTags, userId) {
            userTagsForAdd = userTags;
            userAddTag = userId;
            showAddTag.value = true;
        };
        const putTag = function () {
            if (!newTag.value.trim()) {
                Toast("输入的标签不能为空");
            } else {
                if (!userTagsForAdd.system) {
                    userTagsForAdd.system = [];
                }
                AddTag(userAddTag, newTag.value,
                    function (tag) {
                        userTagsForAdd.system.push(tag);
                        userTagsForAdd = null;
                    },
                    function (e) {
                        Toast("标签添加失败: " + e);
                        userTagsForAdd = null;
                    }
                );
            }
            newTag.value = "";
            userAddTag = null;
            showAddTag.value = false;
        };

        const removePersonalTag = function (tagIndex, tags, userId) {
            RemoveTag(userId, tagIndex, "personal",
                function () {
                    tags.splice(tagIndex, 1);
                },
                function () {
                    Toast("标签移除失败");
                }
            );
        };

        const removeSystemTag = function (tagIndex, tags, userId) {
            RemoveTag(userId, tagIndex, "system",
                function () {
                    tags.splice(tagIndex, 1);
                },
                function () {
                    Toast("标签移除失败");
                }
            );
        };

        const goStudentClass = function (student) {
            router.push("/studentClass/" + student.userId);
        };

        const confirmPassword = ref(null);
        let studentToDelete = null;
        const deleteStudent = async function (student) {
            studentToDelete = student;
            try {
                const name = student.profile.firstName + "_" + student.profile.lastName;
                await Dialog.confirm({
                    title: "确认要删除学生吗？",
                    message: "将要删除学生[" + name + "]，此操作不可恢复，确定吗？",
                    confirmButtonColor: "#ee0a24"
                });
                confirmPassword.value.showDialog();
            } catch (e) {
                return;
            }
        };
        const confirmDelete = async function () {
            const ok = await DeleteStudent(studentToDelete.userId);
            if (ok) {
                students.value.splice(students.value.findIndex(item => item.userId === studentToDelete.userId), 1);
                pageController.removeItem(function (list) {
                    list.splice(list.findIndex(item => item.userId === studentToDelete.userId), 1);
                });
            }
            studentToDelete = null;
        };
        const goStudentPayments = function (student) {
            router.push("/studentDetails/" + student.userId + "/pay/all");
        };
        return {
            Env,
            students,
            viewProfileRef,
            showProfile,
            reportActive,
            showAddTag,
            newTag,
            putTag,
            removePersonalTag,
            removeSystemTag,
            showTagInput,
            goStudentClass,
            deleteStudent,
            confirmPassword,
            confirmDelete,
            isManager: computed(() => UserTypes.manager.value === store.state.user.userType),
            canManageStudent: computed(() => store.state.user.manStudent),
            goStudentPayments,
            rows,
            cols,
            table,
            listMode,
            nextPage,
            lastPage,
            pageOfList,
            setPage,
            maxPage
        };
    }
}
</script>
