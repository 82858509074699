import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import {
    Notify
} from "vant";
import Env from "@/logics/Envs.js";

export default async function (studentIds, returnDict, onlyActive) {
    try {
        const toSend = new FormData();
        if (studentIds) {
            toSend.append("studentIds", studentIds);
        }
        if (returnDict) {
            toSend.append("returnDict", returnDict);
        }
        if (onlyActive) {
            toSend.append("onlyActive", onlyActive);
        }
        const res = await axios.post(Env.apiPath + "listStudent", toSend);
        if (res.data.error) {
            Notify({
                type: "danger",
                message: res.data.error,
                duration: 5000
            });
            return null;
        }
        if (returnDict) {
            return res.data.dict;
        }
        return res.data.list;
    } catch (err) {
        CheckHttp(err);
        return null;
    }
}