<template>
    <van-dialog v-model:show="show" title="请再次输入您的账户密码以保确认" show-cancel-button @confirm="sendPass()"
        :confirm-button-color="'#ee0a24'">
        <div class="text-center">
            <input class="border border-red-600 w-10/12 p-1 my-4" type="password" v-model="password"
                @keyup.enter="sendPass()" />
        </div>
    </van-dialog>
</template>

<script>
import {
    ref
} from "vue";
import {
    Dialog,
    Toast
} from "vant";
import ConfirmPass from "@/asyncs/ConfirmPassword.js";
export default {
    components: {
        [Dialog.Component.name]: Dialog.Component
    },
    setup(props, {
        emit
    }) {
        const show = ref(false);
        const password = ref("");
        const showDialog = function () {
            password.value = "";
            show.value = true;
        };
        const sendPass = async function () {
            const passOk = await ConfirmPass(password.value);
            password.value = "";
            if (passOk) {
                emit("passwordOk");
            } else {
                Toast.fail("密码不一致");
            }
            show.value = false;
        };
        return {
            show,
            password,
            showDialog,
            sendPass
        };
    }
}
</script>
