import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import Env from "@/logics/Envs.js";

export default async function (password) {
    try {
        const toSend = new FormData();
        toSend.append("password", password);
        const res = await axios.post(Env.apiPath + "confirmPass", toSend);
        return res.data.passOk;
    } catch (err) {
        CheckHttp(err);
        return false;
    }
}