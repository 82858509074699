import axios from "axios";
import Env from "@/logics/Envs.js";

export default function (userId, tag, success, fail) {
    const toSend = new FormData();
    toSend.append("userId", userId);
    toSend.append("tag", tag);

    axios.post(Env.apiPath + "addTag", toSend).then(function (res) {
        if (res.data.error) {
            fail(res.data.error);
            return;
        }
        success(tag);
    }).catch(function (e) {
        fail(e);
    });
}