import axios from "axios";
import Env from "@/logics/Envs.js";

export default function (userId, tagIndex, tagType, success, fail) {
    const toSend = new FormData();
    toSend.append("userId", userId);
    toSend.append("removeIndex", tagIndex);
    toSend.append("removeTagType", tagType);

    axios.post(Env.apiPath + "removeTag", toSend).then(function (res) {
        if (res.data.error) {
            fail(res.data.error);
            return;
        }
        success();
    }).catch(function (e) {
        fail(e);
    });
}