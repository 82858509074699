export default class PaginationController {
    constructor(pageSize, list) {
        this.currentPage = 1;
        this.pageSize = pageSize;
        this.total = list.length;
        this.list = list;
        this.endPage = Math.ceil(this.total / pageSize);
    }
    removeItem(removeCall) {
        removeCall(this.list);
        this.total = this.list.length;
        this.endPage = Math.ceil(this.total / this.pageSize);
    }
    page() {
        const start = this.pageSize * (this.currentPage - 1);
        const end = start + this.pageSize;
        return this.list.slice(start, end);
    }
    nextPage() {
        if (this.currentPage >= this.endPage) {
            return null;
        }
        this.currentPage += 1;
        return this.page();
    }
    lastPage() {
        if (this.currentPage <= 1) {
            return null;
        }
        this.currentPage -= 1;
        return this.page();
    }
    setPage(p) {
        if (p <= 1) {
            this.currentPage = 1;
        }
        else if (p >= this.endPage) {
            this.currentPage = this.endPage;
        } else {
            this.currentPage = p;
        }
        return this.currentPage;
    }
}